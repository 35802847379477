import React from 'react';
import RichContentViewer from '../rich-content-viewer/rich-content-viewer';
import PostContent from '../post-content/post-content';
import { flowRight } from 'lodash';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { connect } from '../../../common/components/runtime-context';
import {
  CATEGORY_GUIDELINES,
  getDisplayGuidelinesTypeFromStore,
  getGuidelinesContent,
} from '../../selectors/guidelines-selectors';
import PropTypes from 'prop-types';
import ViewportIosContainer from '../../containers/viewport-ios-container/viewport-ios-container';
import { ModalFromRight } from '../modals/base';
import { ReactComponent as Close } from 'wix-ui-tpa/dist/src/assets/icons/Close.svg';
import styles from './post-guidelines-modal.scss';
import { Text } from 'wix-ui-tpa/Text';
import { IconButton } from 'wix-ui-tpa/IconButton';
import { Button } from 'wix-ui-tpa/Button';
import classNames from 'classnames';

const PostGuidelinesModal = ({ t, type, content, onClose }) => {
  return (
    <ViewportIosContainer dataHook="guidelines-section" className={styles.viewport}>
      <ModalFromRight className={styles.modal} onClose={onClose}>
        <div className={styles.header}>
          <Text className={styles.title}>
            {t(
              type === CATEGORY_GUIDELINES
                ? 'guidelines.title.category-guidelines'
                : 'guidelines.title.forum-guidelines',
            )}
          </Text>
          <IconButton data-hook="guidelines-modal-close" onClick={onClose} icon={<Close />} />
        </div>

        <div className={styles.content}>
          <RichContentViewer
            initialState={content}
            readOnly
            origin="post"
            type={PostContent.POST_PAGE}
          />
        </div>

        <div
          className={classNames(
            styles.footer,
            'forum-card-background-color',
            'forum-card-border-color',
          )}
        >
          <Button fullWidth priority="basic" onClick={onClose} size="medium">
            {t('guidelines.modal.okay-button')}
          </Button>
        </div>
      </ModalFromRight>
    </ViewportIosContainer>
  );
};

PostGuidelinesModal.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
  categoryId: PropTypes.string,
  content: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, props) => ({
  content: getGuidelinesContent(state)(props.categoryId),
  type: getDisplayGuidelinesTypeFromStore(state, props.categoryId),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(PostGuidelinesModal);
