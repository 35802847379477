import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight } from 'lodash';
import classNames from 'classnames';
import InputText from '../input-text';
import RichContentEditor from '../rich-content-editor-async';
import RichContentEditorFooter from '../rich-content-editor-footer';
import postFormSettings from '../../services/post-form-settings';
import { isDiscussion } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withFontClassName from '../../hoc/with-font-class-name';
import { withFastForm } from '../../../common/components/fast-form';
import { connect } from '../../../common/components/runtime-context';
import { SolvedIcon } from '../icons/solved-icon';
import getPostTheme from '../rich-content-editor/theme-post-form';
import styles from './post-form-mobile.scss';
import {
  EXPERIMENT_NEW_QUESTION_PLACEHOLDER,
  EXPERIMENT_POST_CREATION_GUIDELINES,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import withExperiment from '../../hoc/with-experiment';
import { getQuestionPlaceholder } from './post-form-common';
import CategoryDropdownSelect from '../category-dropdown-select/category-dropdown-select';
import { areGuidelinesAvailable, areGuidelinesVisible } from '../../selectors/guidelines-selectors';
import { TextButton } from 'wix-ui-tpa/TextButton';
import PostGuidelinesModal from '../post-guidelines-modal';

export class PostFormMobile extends Component {
  onContentChange = value => this.props.fastForm.changeValue('content')(value);

  render() {
    const {
      postId,
      contentFontClassName,
      titleFontClassName,
      fastForm: { values, changeValue, isValid },
      postType,
      t,
      isNewQuestionPlaceholderEnabled,
      isPostCreationGuidelinesEnabled,
      areGuidelinesAvailableFn,
      areGuidelinesVisibleFn,
      onHideGuidelines,
      onShowGuidelines,
    } = this.props;
    const titleValue = values.title || '';
    const onTitleChange = value => changeValue('title')(value);
    const containerClassName = classNames(
      styles.container,
      'forum-text-color',
      contentFontClassName,
    );
    const isDiscussionType = isDiscussion(postType);
    let areGuidelinesForCategoryAvailable = false;
    let areGuidelinesForCategoryVisible = false;

    if (isPostCreationGuidelinesEnabled) {
      areGuidelinesForCategoryAvailable = areGuidelinesAvailableFn(values.categoryId);
      areGuidelinesForCategoryVisible = areGuidelinesVisibleFn(values.categoryId);
    }

    return (
      <div
        className={classNames(
          containerClassName,
          'forum-card-background-color',
          'forum-card-border-color',
        )}
      >
        {areGuidelinesForCategoryVisible && (
          <PostGuidelinesModal
            categoryId={values.categoryId}
            onClose={() => onHideGuidelines(values.categoryId)}
          />
        )}
        <div className={styles.content}>
          {isPostCreationGuidelinesEnabled && (
            <div className={styles.categorySelectWrapper}>
              {!postId && (
                <div className={styles.categorySelect}>
                  <CategoryDropdownSelect
                    initiallySelectedId={values.categoryId}
                    onChange={category => {
                      changeValue('categoryId')(category._id);
                    }}
                  />
                </div>
              )}
              {areGuidelinesForCategoryAvailable && (
                <TextButton
                  data-hook="toggle-guidelines"
                  className={styles.guidelinesTrigger}
                  priority="primary"
                  onClick={() => onShowGuidelines(values.categoryId)}
                >
                  {t('post-form.show-guidelines')}
                </TextButton>
              )}
            </div>
          )}
          <div className={styles.titleInputWrapper}>
            <InputText
              value={titleValue}
              onChange={onTitleChange}
              containerClassName={classNames(titleFontClassName, styles.titleInputContainer)}
              className={classNames(styles.titleInput, 'forum-text-color')}
              maxLength={140}
              aria-label={t('post-form.title')}
              placeholder={t(
                isDiscussionType
                  ? 'post-form.give-this-post-a-title'
                  : 'post-form.your-question-here',
              )}
              autoFocus={!postId}
              data-hook="post-form__title-input"
              inputRef={r => (this.titleInputRef = r)}
            />
          </div>
          <div className={classNames(styles.editor, 'post-form__text-editor')}>
            <RichContentEditor
              initialState={values.content || undefined}
              placeholder={t(
                isDiscussion(postType)
                  ? 'text-editor.placeholder'
                  : getQuestionPlaceholder(isNewQuestionPlaceholderEnabled, t),
              )}
              onChange={this.onContentChange}
              focus={false}
              origin="post"
              showAdvancedImageSettings={false}
              themeGetter={getPostTheme}
              setRef={ref => (this.editor = ref)}
            />
          </div>
        </div>
        <RichContentEditorFooter submitIcon={postId && SolvedIcon} isDisabled={!isValid} />
      </div>
    );
  }
}

PostFormMobile.propTypes = {
  onSubmitButtonClick: PropTypes.func.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  t: PropTypes.func,
  postId: PropTypes.string,
  showMessage: PropTypes.func,
  fastForm: PropTypes.object,
  postType: PropTypes.string,
  isNewQuestionPlaceholderEnabled: PropTypes.bool,
  isPostCreationGuidelinesEnabled: PropTypes.bool,
  areGuidelinesAvailableFn: PropTypes.func.isRequired,
  areGuidelinesVisibleFn: PropTypes.func.isRequired,
  onHideGuidelines: PropTypes.func.isRequired,
  onShowGuidelines: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  showMessage: actions.showMessage,
  areGuidelinesAvailableFn: areGuidelinesAvailable(state),
  areGuidelinesVisibleFn: areGuidelinesVisible(state),
  onHideGuidelines: categoryId => actions.hideGuidelines(categoryId),
  onShowGuidelines: categoryId => actions.showGuidelines(categoryId),
});

export default flowRight(
  withFastForm(postFormSettings),
  connect(mapRuntimeToProps),
  withTranslate,
  withFontClassName,
  withExperiment({
    isNewQuestionPlaceholderEnabled: EXPERIMENT_NEW_QUESTION_PLACEHOLDER,
    isPostCreationGuidelinesEnabled: EXPERIMENT_POST_CREATION_GUIDELINES,
  }),
)(PostFormMobile);
